// 职业技能培训项目 - TopThree
export const vocSkillTopList = [
  {
    id:'jzfwy',
    code: '4-10-01-06',
    title: '家政服务员',
    desc: `家政服务员是从事料理家务、照护家庭成员、管理家庭事务的人员。依据家政服务员职业活动特点，家政服务员职业细分为母婴护理员工种，家务服务员工种和家庭照护员工种三类。家政服务员分为五级/初级工、四级/中级工、三级/高级工和二级/技师四个等级。`,
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/jiazhengWaiter.png',
  },
  {
    id:'ylhly',
    code: '4-10-01-05',
    title: '养老护理员',
    desc: `    养老护理员是具备一定的表达能力与学习能力，手指、手臂灵活，动作协调，有一定的有空间感与色觉能力，从事老年人生活照料、护理服务等工作的专业人员。这一职业人员是居家和社会以及机构养老服务的主要提供者，是养老服务体系的重要支撑保障，是解决家庭难题、缓解社会问题、促进社会和谐的重要力量。
    依据2019年国家标准，养老护理员职业分设五个等级，分别为五级/初级工、四级/中级工、三级/高级工、二级/技师、一级/高级技师。`,
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/yanglaohuliyuan.png',
  },
  {
    id:'yyy',
    code: '4-10-01-02',
    title: '育婴员',
    desc: `    育婴员是指在 0～3 岁婴幼儿家庭从事婴幼儿日常生活照料、护理和辅助早期成长的人员。育婴员职业共设三个等级，分别为：五级/初级工、四级/中级工、三级/高级工。
    3岁以下婴幼儿照护服务是生命全周期服务管理的重要内容，事关婴儿健康成长，事关千家万户。在人民群众对婴幼儿照护服务的需求变得日益强烈的今天，婴幼儿照护人员的需求量必然迅猛增加。`,
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/yuyingyuan.png',
  },
];

// 职业技能培训项目 - BottomFour
export const vocSkillBottomList = [
  // {
  //   code: '4-14-02-02',
  //   title: '健康管理师',
  //   desc: `    健康管理师是指具备一定的观察、理解、表达、交流、协调、学习能力，以及信息的获取、使用和管理能力，从事个体和群体从营养和心理两方面健康的检测、分析、评估以及健康咨询、指导和危险因素干预等工作的专业人员。
  //   中央数次强调要提高基层防病治病和健康管理能力，健康产业作为朝阳产业，具有巨大的市场潜力，在健康中国战略和人口老龄化的今天，健康类行业势必发展迅猛。`,
  //   imgUrl:
  //     'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/jiankangguanlishi.png',
  // },
  // {
  //   code: '4-14-01-02',
  //   title: '健康照护师',
  //   desc: `    健康照护师是运用基本医学护理知识与技能，在家庭、医院、社区等场所，为照护对象提供健康照护及生活照料的人员。这一类职业人员可在家庭、医院、社区、养老机构等场所提供服务，承担老年人、孕产妇、婴幼儿、病患者和残障人士健康照护与生活照料双重职能。 
  //   依据2020年国家标准，健康照护师职业分设五个等级，分别为五级/初级工、四级/中级工、三级/高级工、二级/技师、一级/高级技师。`,
  //   imgUrl:
  //     'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/jiankangzhaohushi.png',
  // },
  {
    id:'ggyys',
    title: '公共营养师',
    code:'4-14-02-01',
    desc: '公共营养师是应社会、市场的需要而产生的职业技能人才，是在社区、学校、家庭和基层卫生服务的重要力量。公共营养师为接受专业知识技能培训，从事营养指导、营养与食品安全知识传播，促进社会公众健康工作的专业人员。',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/gonggongyingyangshi1.png',
  },
  {
    id:'lnrnlpgs',
    title: '老年人能力评估师',
    code:'4-14-02-05',
    desc: '为有需求的老年人提供日常生活活动能力、认知能力、精神状态等健康状况测量与评估的人员。',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhiyejineng/laonian1.png',
  },
];

// 专项技能培训项目 - TopFour
export const specialSkillTopList = [
  {
    title: '家政职业经理人',
    id:'jzzyjlr',
    gongzhong:'4-10-01-11-01',
    code:'4-10-01-11',
    desc: '为促进家政经理管理型人才专业化、职业化建设，提高家政职业经理人队伍整体综合素养，“推进中国企业经理职业化，培养中国企业职业经理人”，受聘于家政企业，担任不同层级的领导和管理职务，承担相应的义务和责任，从事家政经营管理活动。',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/jiazheng.png',
  },
  {
    title: '小儿推拿',
    id:'xetn',
    gongzhong:'4-10-01-02-02',
    code:'4-10-01-02',
    desc: '小儿推拿是以中医理论体系为指导，根据小儿推拿，病理特点，运用各种手法用于小儿体表穴位，使经络通畅，气血流通，已达到治疗小儿疾病和小儿保健的中医外治方法。',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/xiaoer.png',
  },
  {
    gongzhong:'4-10-01-01-01',
    code:'4-10-01-01',
    id:'zjzd',
    title: '早教指导',
    desc: '为0-3岁婴幼儿和主要带养人提供早教服务，主要负责婴儿的早期教育，融合蒙台梭利感官教学法与游戏教学法，通过操作、游戏、音乐、艺术等丰富多彩的形式，促进婴儿的智力发展，解决婴幼儿常见教育问题，帮助婴儿形成良好的生活习惯及独立自信的性格。',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/zaojiao.png',
  },
  {
    title: '产后康复',
    id:'chkf',
    code:'4-10-01-06',
    gongzhong:'4-10-01-06-05',
    desc: '产后康复是指在科学健康理念的指导下，利用传统医学与现代科学相结合，针对妇女产后这一特殊时期的心理和生理变化，对其进行主动、系统的康复指导。随着二胎政策的推动，未来人口将再次迎来了一次高峰期。也将是产后修复行业呈现蓬勃发展的高峰阶段。为提高该行业服务水平，特制定本文件。',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/chanhou.png',
  },
];

// 专项技能培训项目 - BottomThree
export const specialSkillBottomList = [
  {
    gongzhong: '4-10-01-05-01',
    code:'4-10-01-05',
    id:'snszzh',
    title: '失能失智照护（老年人照护）',
    desc: `当前失能老人服务需求已经非常凸显，急需养老服务人才。随着老龄化人口加剧进程，与此同时，失智症发病率不断上升，同样已成为威胁老年人健康的严重隐患。失能失智老年人照护是目前以及将来的社会问题，是“疼点”，也是“难点”。
    为促进复合型养老服务职业技能人才的培训教育，促使失能失智老年人照护人员职业技能培训标准化，规范化，科学化，可实施，定向化，特编制本文件。`,
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/img-sqyl.png',
  },
  // {
  //   title: '社区养老服务',
  //   desc: '专项技能培训项目正在建设中...',
  //   imgUrl:
  //     'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/shequyanglaofuwu.png',
  // },
  {
    title: '师资培训项目',
    id:'szpxxm',
    desc: '家政师资培训课程是联合行业协会、家政企业组织家政培训师培训，解决全国家政培训讲师标准赋能、认证、师资委派，进入平台师资库，可承接全国培训授课。为保障师资培训课程的质量，提高家政师资的教学水平，人社教培中心特开展师资培训课程。对完成师资培训的讲师，可获得人教培认证的讲师证书，并可承担人教培组织的培训课程。',
    code:'4-10-01-02',
    spec:'teacher',
    imgUrl:
      'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhuanxiangjineng/shizipeixun.png',
  },
];
export const teacherIntro = {
  vocation_name:'师资培训项目',
  vocation_intro:'家政师资培训课程是联合行业协会、家政企业组织家政培训师培训，解决全国家政培训讲师标准赋能、认证、师资委派，进入平台师资库，可承接全国培训授课。为保障师资培训课程的质量，提高家政师资的教学水平，人社教培中心特开展师资培训课程。对完成师资培训的讲师，可获得人教培认证的讲师证书，并可承担人教培组织的培训课程。'
}
// 师资培训
export const teacherData = [{
  name:'全部',
  gongzhong:'',
  workTypeList:[
    {
      vocationName:'全部',
      gongzhong:''
    },
    {
      vocationName:'育婴员',
      gongzhong:'4-10-01-02-98'
    },{
      vocationName:'小儿推拿',
      gongzhong:'4-10-01-02-02'
    },
    {
      vocationName:'母婴护理员',
      gongzhong:'4-10-01-06-03'
    },{
      vocationName:'产后康复',
      gongzhong:'4-10-01-06-05'
    },
    // {
    //   vocationName:'整理收纳师',
    //   gongzhong:'4-10-01-06-04'
    // },
    // {
    //   vocationName:'家务服务员',
    //   gongzhong:'4-10-01-06-01'
    // },{
    //   vocationName:'家庭照护员',
    //   gongzhong:'4-10-01-06-02'
    // },
    {
      vocationName:'养老护理员',
      gongzhong:'4-10-01-05-02'
    },{
      vocationName:'失能失智照护（老年人照护）',
      gongzhong:'4-10-01-05-01'
    },{
      vocationName:'老年人能力评估师',
      gongzhong:'4-14-02-05-01'
    },
    // {
    //   vocationName:'健康管理师',
    //   gongzhong:'4-14-02-02-01'
    // },
     {
      vocationName:'公共营养师',
      gongzhong:'4-14-02-01-02'
    }, {
      vocationName:'早教指导',
      gongzhong:'4-10-01-01-01'
    }
  ]
},
  {
    name:'育婴员',
    zhiye:'4-10-01-02',
    workTypeList:[
      {
        vocationName:'全部',
        gongzhong:''
      },
      {
        vocationName:'育婴员',
        gongzhong:'4-10-01-02-98'
      },{
        vocationName:'小儿推拿',
        gongzhong:'4-10-01-02-02'
      }
    ]
  }, {
    name:'家政服务员',
    zhiye:'4-10-01-06',
    workTypeList:[
      {
        vocationName:'全部',
        gongzhong:''
      },
      {
        vocationName:'母婴护理员',
        gongzhong:'4-10-01-06-03'
      },{
        vocationName:'产后康复',
        gongzhong:'4-10-01-06-05'
      },
      // {
      //   vocationName:'整理收纳师',
      //   gongzhong:'4-10-01-06-04'
      // },
      // {
      //   vocationName:'家务服务员',
      //   gongzhong:'4-10-01-06-01'
      // },
      // {
      //   vocationName:'家庭照护员',
      //   gongzhong:'4-10-01-06-02'
      // }
    ]
  },
  {
    name:'养老护理员',
    zhiye:'4-10-01-05',
    workTypeList:[
      {
        vocationName:'全部',
        gongzhong:''
      },
      {
        vocationName:'养老护理员',
        gongzhong:'4-10-01-05-02'
      },{
        vocationName:'失能失智照护（老年人照护）',
        gongzhong:'4-10-01-05-01'
      }
    ]
  }, {
    name:'老年人能力评估师',
    zhiye:'4-14-02-05',
    workTypeList:[
      {
        vocationName:'全部',
        gongzhong:''
      },
      {
        vocationName:'老年人能力评估师',
        gongzhong:'4-14-02-05-01'
      }
    ]
  },
  // {
  //   name:'健康管理师',
  //   zhiye:'4-14-02-02',
  //   workTypeList:[
  //     {
  //       vocationName:'全部',
  //       gongzhong:''
  //     },
  //     {
  //       vocationName:'健康管理师',
  //       gongzhong:'4-14-02-02-01'
  //     }
  //   ]
  // },
  {
    name:'公共营养师',
    zhiye:'4-14-02-01',
    workTypeList:[
      {
        vocationName:'全部',
        gongzhong:''
      },
      {
        vocationName:'公共营养师',
        gongzhong:'4-14-02-01-02'
      }
    ]
  },
  {
    name:'婴幼儿发展引导员',
    zhiye:'4-10-01-01',
    workTypeList:[
      {
        vocationName:'全部',
        gongzhong:''
      },
      {
        vocationName:'早教指导',
        gongzhong:'4-10-01-01-01'
      }
    ]
  },
]

// 试听课 - 课程名称列表 - Mock数据
export const trialCourseListMock = [
  {
    id: 1,
    courseName: '养老护理员',
    children: [
      {
        courseId: 1,
        levelName: '初级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/minzheng/try1.jpg',
        videoUrl:
          'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/2bff074a-17a7a7ec990-0005-cd1a-d1c-c3eb7.mp4',
        isPlaying: false,
      },
      {
        courseId: 2,
        levelName: '中级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
      {
        courseId: 3,
        levelName: '高级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/big_buck_bunny.mp4',
        isPlaying: false,
      },
      {
        courseId: 4,
        levelName: '超级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
    ],
  },
  {
    id: 2,
    courseName: '育婴员',
    children: [
      {
        courseId: 1,
        levelName: '一级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
      {
        courseId: 2,
        levelName: '二级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
      {
        courseId: 3,
        levelName: '三级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
      {
        courseId: 4,
        levelName: '四级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
    ],
  },
  {
    id: 3,
    courseName: '养老护理员',
    children: [
      {
        courseId: 1,
        levelName: '测试一级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
      {
        courseId: 2,
        levelName: '测试二级',
        videoName: '协助老年人床上被动翻身',
        courseDesc:
          '定时协助老年人转换体位，可以促进卧床老年人的血液循环，预防压疮、坠积性肺炎、尿路感染、肌肉萎缩、关节变形、肢体挛缩等并发症的发生。本节课程将为大家讲解如何协助老年人进行正确的床上被动翻身。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/6BCF2B2F-1197-4D79-9E68-E503671D5ECB.png',
        videoUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/mov_bbb.mp4',
        isPlaying: false,
      },
    ],
  },
  {
    id: 4,
    courseName: '母婴护理员',
    children: [],
  },
  {
    id: 5,
    courseName: '家务服务员',
    children: [],
  },
  {
    id: 6,
    courseName: '家务服务员',
    children: [],
  },
];

// 试听课 - 课程名称列表
export const trialCourseList = [
  {
    id: 1,
    courseName: '养老护理员',
    children: [
      {
        courseId: 1,
        levelName: '养老护理员五级（初级）',
        // 视频名称
        videoName: '噎食、误吸',
        courseDesc:
          '《对老年人发生噎食、误吸的急救》实操视频属于生活照护服务项目。整片视频科学合理，符合学习逻辑。总分设计，具体设置为操作目的、操作流程和步骤、操作注意事项。进而细分步骤，学习节奏清晰，流畅，便于学习理解和掌握。从以下几个方面更是体现了授课效果，操作前采用情景案例的导入，模拟真实照护场景，适老化环境和教具的适配，配合教学效果完整真实的体现。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/carePoster1.jpeg',
        videoUrl:
          'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/3ade9cd9-17a0f368e94-0005-cd1a-d1c-c3eb7.mp4',
        isPlaying: false,
      },
      {
        courseId: 2,
        levelName: '养老护理员四级（中级）',
        // 视频名称
        videoName: '适合老年人的体育运动',
        courseDesc:
          '《适合老年人体育运动》视频属于康复服务项目。教学内容专业，详尽，分别讲解了散步、健步走、体操、呼吸健肺操的方法和注意事项。视频画面文字内容配合图表和图片，更加生动形象，体现教学的趣味性，有利于提升学习兴趣，便于学习内容的理解记忆和掌握。',
        posterUrl:
          'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/shiting/carePoster2.jpeg',
        videoUrl:
          'https://platform-vod.wdcloudnet.com/customerTrans/928e23e5005956f70e333d6b586f6f88/3b3bc327-17a51257cce-0005-cd1a-d1c-c3eb7.mp4',
        isPlaying: false,
      },
    ],
  },
];
