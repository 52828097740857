<template>
    <div class="projectIntro">
        <div class="projectIntro-top">
            <div class="content">
                <h2>{{vocationInfo.vocation_name}}</h2>
                <p>{{vocationInfo.vocation_intro}}</p>
            </div>
        </div>
        <div class="projectIntro-middle" v-if="workInfoList.length === 1 && $route.query.spec!='teacher'">
            <div class="content">
                <div class="left">
                    <h2>{{workInfoList[0].work_name}}</h2>
                    <p>{{workInfoList[0].work_intro}}</p>
                </div>
                <div class="right" :class="{gray:!workInfoList[0].work_url || ['整理收纳师','家务服务员','家庭照护员'].includes(workInfoList[0].work_name)}" @click="docPreview(workInfoList[0])">
                    <p class="btn" >立即查看</p>
                </div>
            </div>
        </div>
        <el-carousel v-else-if="$route.query.spec!='teacher'" :interval="5000" height="160px" :autoplay="false" indicator-position="outside" arrow="always" :initial-index="workTypeIndex">
            <el-carousel-item v-for="item in workInfoList" :key="item.id" class="content">
                <div class="left">
                    <h2>{{item.work_name}}</h2>
                    <p>{{item.work_intro}}</p>
                </div>
                <div class="right" :class="{gray:!item.work_url || ['整理收纳师','家务服务员','家庭照护员'].includes(item.work_name)}" @click="docPreview(item)">
                    <p class="btn">立即查看</p>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="projectIntro-goodsList" :style="workInfoList.length > 1 ? 'margin-top:-60px':'margin-top:-20px;'">
            <div class="classification">
                <div v-if="$route.query.spec=='teacher'" class="teacher-box">
                    <div class="workType">
                        <p class="label">培训项目：</p>
                        <div class="item" v-for="(item,index) in teacherTrain" @click="handleTeacherWork(item,index)" :class="{active:currentWork == index}" :key="index+'workType'">{{item.name}}</div>
                    </div>
                    <div class="skillLevel">
                        <p class="label">培训工种：</p>
                        <div class="skillItemList">
                            <div class="item" v-for="(item,index) in teacherTrain[currentWork].workTypeList" @click="teacherWorkType(item,index)" :class="{active:item.gongzhong===goodsListParams.gongzhong || (!goodsListParams.gongzhong&&index==0)}" :key="index+'workType'">{{item.vocationName}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="workType">
                        <p class="label">工种分类：</p>
                        <div class="item" v-for="(item,index) in workTypeList" @click="workTypeClick(item,index)" :class="{active:(item.vocationCode===goodsListParams.gongzhong)&&goodsListParams.gongzhong || (!goodsListParams.gongzhong&&currentWorkIndex===index)}" :key="index+'workType'">{{item.vocationName}}</div>
                    </div>
                    <div class="skillLevel">
                        <p class="label">技能等级：</p>
                        <div class="itemList">
                            <div class="item" v-for="(item,index) in skillLevelList" @click="skillLevelClick(item,index)" :class="{active:index===skillLevelIndex}" :key="index+'skillLevel'">{{item.vocationName}}</div>
                        </div>
                    </div>
                    <div class="trainMethod">
                        <p class="label">培训方式：</p>
                        <div class="item" v-for="(item,index) in trainMethodList" @click="trainMethodClick(item,index)" :class="{active:index===trainMethodIndex}" :key="index+'trainMethod'">{{item.name}}</div>
                    </div>
                </div>
                <div class="sort" v-if="token">
                    <div class="left">
                        <p class="label">排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</p>
                        <div class="item" v-for="(item,index) in sortList" @click="sortClick(item)" :class="{active:item.id===sortIndex}" :key="index+'sort'">{{item.name}}</div>
                    </div>
                    <div class="right">
                        <el-input class="search" type="text" ref="searchInp" placeholder="请输入关键词搜索" v-model="goodsListParams.name">
                            <el-button class="btn" slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="list" :style="token ? 'margin-top:0;' : 'margin-top:24px;'" v-if="isGoodsList">
                <div class="item" v-for="item in goodsList" :key="item.id" @click="goGoodsDetail(item)">
                    <div class="commonTag">
                        <p class="trainMethod" v-if="item.trainingMethodName">{{item.trainingMethodName}}</p>
                        <template v-for="(el,ind) in transformList(item.labelNameList)">
                            <span class="teacherTag" :key="ind">{{el}}</span>
                        </template>
                    </div>
                    <img :src="item.pic" class="pic" alt="">
                    <div class="middle">
                        <h2 :title="item.name">{{item.name}}</h2>
                        <p class="trainingTime time" v-if="item.cycleType == 1">培训周期：{{item.trainingStartTime && item.trainingStartTime.split(' ')[0].replace(/-/g, '.')}} - {{item.trainingEndTime && item.trainingEndTime.split(' ')[0].replace(/-/g, '.')}}</p>
                        <p class="trainingTime time" v-if="item.cycleType == 2">培训周期：随到随学</p>
                        <p class="trainingTime time" v-if="item.cycleType == 3">培训周期：长期有效</p>
                        <p class="signUpTime time">报名周期：{{item.signUpStartTime && item.signUpStartTime.split(' ')[0].replace(/-/g, '.')}} - {{item.signUpEndTime && item.signUpEndTime.split(' ')[0].replace(/-/g, '.')}}</p>
                        <div class="branch">
                            <p class="branchLogo" v-if="item.resourceProviderLogo">
                                <!-- <img src="./../asset/images/logo.png" alt=""> -->
                                <img :src="item.resourceProviderLogo" alt="">
                            </p>
                            <!-- <span class="branchName">{{item.branchShortName || item.branchName}}</span> -->
                        </div>
                    </div>
                    <p class="line"></p>
                    <div class="bottom">
                        <div class="islogin" v-if="!token">
                            <p>了解详情</p>
                        </div>
                        <div class="login" v-if="token&&!wdSubBranch">
                            <div class="left">
                                <div v-if="item.isFree === 1">
                                    <!-- <span class="isFree">免费</span> -->
                                </div>
                                <div v-else>
                                    <!-- <p class="">售价：¥{{item.price}}</p>
                                    <p class="">预计收益：<span>¥{{item.commission}}</span></p> -->
                                </div>
                            </div>
                            <div class="right" v-if="!wdSubBranch">
                                <p class="btn">推广</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination" v-if="isGoodsList">
                <span class="courseNum">共 {{paging.total}} 个课程</span>
                <el-pagination
                    @size-change="handleSizeChange"
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.params.pageNum"
                    :page-size="paging.params.pageSize"
                    layout="prev, pager, next, jumper"
                    :total="paging.total">
                </el-pagination>
            </div>
            <div class="noData" v-if="!isGoodsList">
                <img src="../asset/images/zwss.png" alt="">
                <p>暂无内容</p>
            </div>
        </div>
        <div class="projectIntro-certificate">
            <h1>项目证书</h1>
            <div class="content">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhengshu/cert11.png" alt="">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhengshu/cert22.png" alt="">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhengshu/cert33.png" alt="">
            </div>
        </div>
        <div class="footer">
            <div class="con">
                <!-- <p>推荐使用Microsoft Edge、Chrome、360浏览器</p> -->
                <span>主办单位：人力资源和社会保障部社会保障能力建设中心</span>
                <span>京ICP备 13009107号-3</span>
                <span>京公网安备 11010502033661</span>
                <span>技术支持：伟东云教育集团</span>
            </div>
        </div>
        <el-dialog
            title="培训规范详情"
            :visible.sync="dialogVisible"
            width="1000px"
            center
            :before-close="handleClose">
                <div id="Vidpreview" style="width: 100%;min-height: 500px;"></div>
        </el-dialog>
    </div>
</template>
<script>
import { teacherData,teacherIntro} from "./../../renshehome/common/data.js";
export default {
    name:'projectIntro',
    data(){
        return{
            wdSubBranch:false,
            currentWork:1,
            currentWorkIndex:1,
            teacherTrain:teacherData,
            dialogVisible:false,
            workInfoList:[], // 工种 简介
            skillLevelIndex:0, // 等级
            workTypeIndex:0, // 工种分了下标
            trainMethodIndex:0, // 培训方式
            vocationInfo:{}, // 职业简介
            skillLevelList:[
                {
                    id:'',
                    vocationName:'全部',
                }
            ],// 技能等级列表
            trainMethodList:[
                {
                    id:'',
                    name:'全部',
                }
            ],// 培训方式列表
            workTypeList:[
                {
                    id:'',
                    vocationName:'全部',
                }
            ],// 工种列表
            transLevel:{
                1:'01',
                2:'02',
                3:'03',
                4:'04',
                5:'05'
            },
            sortIndex:1,
            sortList:[
                {
                    id:1,
                    name:'智能排序'
                },
                {
                    id:2,
                    name:'最热'
                },
                {
                    id:3,
                    name:'佣金'
                }
            ],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 8
                },
                total: 0,
                totalPages: 0,
                currentSize: 0,
                schoolHour:0,// 课时数
            },
            goodsListParams:{
                branchId:'',// 机构id
                centerType:1,//1 服务商 2 渠道方
                name:'',
                sort:'',//排序规则 1 智能排序；2 最热排序；3 佣金
                fangshi:'',// 培训方式
                gongzhong:'',//工种分类
                jineng:'',//等级
                zhiye:'',// 职业分类
            },
            goodsList:[
            ],// 商品列表
            isGoodsList:true,
            levelAll:[],
            workTypeAll:[],

        }
    },
    computed: {
        token(){
            return this.$store.state.token
        }
	},
    created(){
        if(this.token || localStorage.getItem('token')){
             this.currBranchInfo();
        }
        this.goodsListParams.branchId =  localStorage.getItem('branchId') || "";
        this.goodsListParams.zhiye = this.$route.query.code;
        this.goodsListParams.gongzhong = this.$route.query.gongzhong;
        this.goodsListParams.sort = this.token ? 1 : '';
        this.currentWorkIndex = this.$route.query.gongzhong ? undefined: 1;
        if(this.$route.query.spec!='teacher'){
            this.goodsListParams.teacherFlag = 2;
            this.getfilterInfo();
            this.getVocationByCode();
        }else{
            // 师资培训（特例）
            this.goodsListParams.teacherFlag = 1;
            this.workTypeList = this.teacherTrain;
            this.vocationInfo = teacherIntro;
            this.getData();
        }
    },
    methods:{
        // 是否为伟东下的渠道方
        currBranchInfo(){
            this.$api.rensheUsercenter.currBranchInfo().then(res=>{
                if(res.data){
                    this.wdSubBranch = res.data.wdSubBranch;
                }
            })
        },
        transformList(list){
            if(this.$route.query.spec=='teacher' && list.length>1){
                let tempList = list.filter(item=>{
                    if(item.indexOf('师资培训')>-1){
                        return item
                    }
                })
                return tempList || []
            }else{
                return list
            }
        },
        handleClose(){
            this.dialogVisible = false;
        },
        handleTeacherWork(item,index){
            this.goodsListParams.zhiye = item.zhiye;
            this.currentWork = index;
            this.goodsListParams.gongzhong = '';
            this.paging.params.pageNum = 1;
            this.getData();
        },
        /** 商品筛选信息 */
        async getfilterInfo(){
            const params = {
                branchId:this.goodsListParams.branchId,
                centerType:'1',
            }
            if(this.$route.query.code=='4-14-02-05'){
                params.tagFlag = 1; //老年人能力评估等级：（技师和高级）
            }
           await this.$api.projectIntro.getfilterInfo({params}).then(res => {
                if(res.data){
                    this.trainMethodList.push(...res.data.label);// 培训方式
                    this.levelAll = res.data.level || []; // 所有等级
                    this.workTypeAll = res.data.workType || []; // 所有工种
                    this.skillLevelList.push(...this.levelAll)
                    res.data.category.forEach(item => {
                        if(item.vocationCode === this.$route.query.code){
                            this.workTypeList.push(...item.subVocations)
                        }
                    })
                }
                if(!this.$route.query.gongzhong && this.$route.query.spec!=='teacher'){
                    this.goodsListParams.gongzhong = this.workTypeList[1].vocationCode;
                }
                this.getData();
            })
        },
        /** 获取职业工种简介信息 */
        getVocationByCode(){
            const params = {
                vocation_code: this.$route.query.code
            }
            this.$api.projectIntro.getVocationByCode({params}).then(res => {
                if(res.data){
                    this.vocationInfo = res.data;
                    this.workInfoList = res.data.workList || [];
                }
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.getData();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getData();
        },
        /** 查看文档 */
        docPreview(item){
            if(!item.work_url || ['整理收纳师','家务服务员','家庭照护员'].includes(item.work_name)){
                this.$toast("标准建设中");
                return false;
            }
            this.dialogVisible = true;
            const params = {
                resId: item.work_url
            }
            this.$api.projectIntro.getDocumentAccessToken({params}).then(res => {
                if (res.success) {
                    // 下边是安全才有的
                    this.$nextTick(() => {
                        // eslint-disable-next-line no-undef
                        let demo = aliyun.config({
                            mount: document.querySelector('#Vidpreview'),
                            url: res.data.previewURL, //设置文档查看URL地址。
                        })
                        // //设置AccessToken。
                        demo.setToken({ token: res.data.accessToken })
                    })
                } 
            })
        },
        /** 跳转商品详情页 */
        goGoodsDetail(item){
            if(this.wdSubBranch){
                this.$message.warning('无法进入学习')
                return
            }
            let fromType = 1;
            if(!this.token){
                fromType = 1;
            }else{
                if(localStorage.getItem('branchId')){
                    fromType = 1;
                }else{
                    fromType = 3;
                }
            }
            this.$router.push({
                path:'mall/goodsDetail',
                query:{
                    goodsId:item.id,
                    goodsKind:item.goodsKind || 2,
                    fromType:fromType, 
                }
            })
        },
        /** 技能等级 */
        skillLevelClick(item,index){
            console.log(this.skillLevelList,'等级');
            console.log(item);
            this.skillLevelIndex = index;
            this.goodsListParams.jineng = item.vocationCode || '';
            this.paging.params.pageNum = 1;
            this.getData();
        },
        /** 培训方式 */
        trainMethodClick(item,index){
            this.trainMethodIndex = index;
            this.goodsListParams.fangshi = item.id || '';
            this.paging.params.pageNum = 1;
            this.getData();
        },
        // 师资工种
        teacherWorkType(item){
            this.goodsListParams.gongzhong = item.gongzhong;
            this.paging.params.pageNum = 1;
            this.getData();
        },
        /** 培训工种分类 */
        workTypeClick(item,index){
            this.currentWorkIndex =  index;
            this.skillLevelList = [{
                vocationCode:'',
                vocationName:'全部',
            }];
            if(item.id == ''){
              this.skillLevelList.push(...this.levelAll)
              this.goodsListParams.jineng = "";
              this.skillLevelIndex = 0;
            }else {
              this.skillLevelList.push(...item.subVocations);
              this.goodsListParams.jineng = "";
              this.skillLevelIndex = 0;
            }
            this.goodsListParams.gongzhong = item.vocationCode || "";
            this.paging.params.pageNum = 1;
            this.getData();
        },
        /** 排序点击 */
        sortClick(item){
            this.sortIndex = item.id;
            this.goodsListParams.sort = item.id;
            this.paging.params.pageNum = 1;
            this.getData();
        },
        searchBtn(){
            this.paging.params.pageNum = 1;
            this.getData()
        },
        /** 商品列表 */
        getData(){
            const data = {
                ...this.goodsListParams,
            }
            const params = {
                ...this.paging.params
            }
            if(data.gongzhong && [1,2,3,4,5].includes(data.jineng)){
                data.jineng =data.gongzhong+'-'+this.transLevel[data.jineng]
            }
            this.$api.projectIntro.getGoodsList({data,params}).then(res => {
                if(res.data){
                    this.goodsList = res.data.list || [];
                    if(this.goodsList.length){
                        this.isGoodsList = true;
                    }else{
                        this.isGoodsList = false;
                    }
                    this.paging.total = res.data.total;
                    this.paging.schoolHour = res.data.schoolHour;
                }else {
                    this.isGoodsList = false;
                }
            }).catch(() =>{
                this.isGoodsList = false;
            })
        },
    }
}
</script>
<style lang="stylus" src="../asset/css/index.styl" scoped>

</style>
